import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../layouts/Layout'
import CoworkerFull from '../components/CoworkerFull'


//Component
const CoworkerTemplate = ( props ) => {

  const current_page = props.data.wordpressWpCoworkers
  const yoast_meta = current_page.yoast_meta
  
  const current_meta = {
    meta_title:     (yoast_meta) ? yoast_meta.yoast_wpseo_title : null,
    meta_desc:      (yoast_meta) ? yoast_meta.yoast_wpseo_metadesc : null,
  }

  const name = current_page.title
  const title = (current_page.acf) ? current_page.acf.label : null
  const mail = (current_page.acf) ? current_page.acf.mail : null
  const phone = (current_page.acf) ? current_page.acf.phone : null
  const about = (current_page.acf) ? current_page.acf.about : null
  const main_image = (current_page.acf) ? current_page.acf.main_image : null
  const main_image_src = (main_image && main_image.localFile) ? main_image.localFile.childImageSharp.fixed : null
  const vertical_image = (current_page.acf) ? current_page.acf.vertical_image : null
  
  return (
    <Layout 
      current_meta = {current_meta} 
    >

      <section className="section section--first">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 coworker-row">
          
              {(main_image_src) ? (
                <div className="row">
                  <div className="col-12">
                    <Img 
                      fixed={main_image_src}
                      alt={main_image.alt} 
                      style={{width: '100%', height: 0, paddingBottom: '57%'}}
                      imgStyle={{width: '100%', height: '100%'}}
                      className="mb-2 coworker-main-image"
                    />
                  </div>
                </div>
              ) : null}

              <div className="row">
                <div className="col-12">

                  <CoworkerFull
                    single={true}
                    name={name}
                    title={title}
                    mail={mail}
                    phone={phone}
                    about={about}
                    vertical_image={vertical_image}
                  />

                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

    </Layout>
  )
}


//Export
export default CoworkerTemplate


//Query
export const coworkerQuery = graphql`
query currentCoworkerQuery($id: String!) {
  wordpressWpCoworkers(id: { eq: $id }) {
    title
    acf {
      label
      mail
      phone
      about
      main_image {
        alt_text
        localFile {
          childImageSharp {
            fixed(height: 600, width: 1040, cropFocus: CENTER, quality: 90) {
              ...Fixed
            }
          }
        }
      }
      vertical_image {
        alt_text
        localFile {
          childImageSharp {
            fixed(height: 850, width: 500, cropFocus: CENTER, quality: 90) {
              ...Fixed
            }
          }
        }
      }
    }
    yoast_meta {
      yoast_wpseo_title
      yoast_wpseo_metadesc
    }
  }
}
`